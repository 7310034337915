<template>
  <div class="kb-nav-round-tabs" data-tabs="sticky">
    <ul v-if="isInit" class="kb-nav-list hide-scroll-bar">
      <li v-for="(menu, idx) in learnMenus" class="kb-nav-item" :key="idx">
        <router-link v-if="menu.path" :to="menu.path" class="kb-nav-link" :class="{'is-active': menu.view === learnView}">
          <span class="kb-nav-text">{{ menu.name }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>

import {learnRouteSetup} from '@/assets/js/modules/learn/learn-setup';

export default {
  name: 'MobileLearnRoute',
  props: {
    isInit: Boolean,
    referenceCnt: Number,
    collectiveCnt: Number,
    boardObjects: Array
  },
  setup: learnRouteSetup
}
</script>
