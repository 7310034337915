<template>
  <div class="content-search mt-3">
    <input v-model.trim="keyword" type="text" class="kb-form-control search-input" placeholder="검색" @keyup.enter="searchKeyword">
    <button class="search-btn" @click="searchKeyword"><i class="icon-search"></i></button>
  </div>

  <article class="content-section">
    <header class="section-header d-flex">
      <div class="dropdown-group">
        <div class="dropdown" data-offset="bottom-left" @click="toggles.tab = !toggles.tab">
          <h3 class="dropdown-btn title">{{ currentObjectNm }} <i class="icon-arrow"></i></h3>
        </div>
        <div v-if="categories.length > 0" class="dropdown" data-offset="bottom-left" @click="toggles.cate = !toggles.cate">
          <h3 class="dropdown-btn title">{{ currentCateNm }} <i class="icon-arrow"></i></h3>
        </div>
      </div>
    </header>
    <MobileLearnBoardTap
        v-model:toggle="toggles.tab"
        :items="boards"
    />
    <MobileLearnBoardCateTap
        v-if="categories.length > 0"
        v-model="filters.lrnBoardCateSn"
        v-model:toggle="toggles.cate"
        :items="categories"
        @selected="selectCategory"
    />
    <!-- notice-list-container -->
    <div class="notice-list-container">
      <!-- list-top -->
      <div class="list-top status">
        <div class="top-column">
          <p class="text text-muted">전체 {{ paging.totalCount }}건</p>
        </div>
      </div>
      <!-- board-list-container -->
      <div v-if="isLoaded" class="board-list-container">
        <template v-if="items.length > 0" >
          <ul class="board-list">
            <!-- board-list-item -->
            <li v-for="(item, index) in items" class="board-list-item" :key="index">
              <router-link :to="{name: 'LearnBoardView', params: {lrnPostSn: item.lrnPostSn}}" class="board-link"></router-link>
              <div class="list-content">
                <div class="list-content-title">
                  <h5 class="title">
                    <span class="text text-truncate">{{ item.title }}</span>
                  </h5>
                </div>
                <div class="list-meta-content">
                  <span class="text">{{ item.lrnerNm }} ({{ item.deptNm }} ・ {{ item.jbgdCd && item.jbgdCd.length >= 2 ? item.jbgdCd.substr(0, 2) : '-' }})</span><span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</span><span class="text">{{ item.inqCnt }} 조회</span>
                </div>
              </div>

              <div v-if="item.fileAtchYn === 'Y'" class="board-column column-file"><a href="javascript:" class="file-link"><i class="icon-file"></i></a></div>
            </li>
          </ul>
          <CommonPaginationFront
              :paging="paging"
              :page-func="pagingFunc"
          />
        </template>
        <div v-else class="search-container">
          <div class="result-empty">
            <img src="@/assets/lxp/images/common/img_empty.png" alt="">
            <p class="text">등록된 게시물이 없습니다.</p>
          </div>
        </div>

      </div>
    </div>
    <!-- //notice-list-container -->
  </article>
</template>

<script>

import CommonPaginationFront from '@/components/CommonPaginationFront';
import {learnBoardSetup} from '@/assets/js/modules/learn/learn-board-setup';
import MobileLearnBoardTap from '@/components/learn/mobile/board/MobileLearnBoardTap';
import MobileLearnBoardCateTap from '@/components/learn/mobile/board/MobileLearnBoardCateTap';

export default {
  name: 'MobileLearnBoard',
  components: {MobileLearnBoardCateTap, MobileLearnBoardTap, CommonPaginationFront},
  props: {
    boards: Array
  },
  setup: learnBoardSetup
};
</script>
