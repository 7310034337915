<template>
  <div class="kb-form-layer" :class="{'is-active' : toggle}" @click.stop="closeToggle">
    <div class="layer-container">
      <div class="layer">
        <div class="kb-form-layer-options">
          <div class="layer-option">
            <ul class="layer-option-list">
              <li class="layer-option-item" :class="{'is-active' : !modelValue}">
                <a href="javascript:" class="layer-option-link" @click.stop="emitCategory(0)"><strong class="layer-option-text">전체</strong></a>
              </li>
              <li v-for="(item, index) in items" class="layer-option-item" :class="{'is-active' : item.lrnBoardCateSn === modelValue}" :key="index">
                <a href="javascript:" class="layer-option-link" @click.stop="emitCategory(item.lrnBoardCateSn)"><strong class="layer-option-text">{{ item.cateNm }}</strong></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MobileLearnBoardCateTap',
  props: {
    modelValue: Number,
    toggle: Boolean,
    items: Array
  },
  emits: ['update:modelValue', 'update:toggle', 'selected'],
  setup(props, {emit}) {
    const closeToggle = () => {
      emit('update:toggle', false);
    }

    const emitCategory = lrnBoardCateSn => {
      if (props.modelValue !== lrnBoardCateSn) {
        emit('update:modelValue', lrnBoardCateSn);
        emit('selected')
      }
      closeToggle();
    }

    return {
      emitCategory,
      closeToggle
    }
  }
};
</script>
