<template>
  <article v-if="evlTestCount > 0" class="content-section pt-0">
    <header class="section-header d-flex">
      <div class="content-title">
        <h3 class="px-2">모의테스트</h3>
      </div>
      <div v-if="tab === 'today'" class="header-side"><span class="text-sm text-muted">{{ timestampToDateFormat(currentDateStr, 'full-kr') }}</span></div>
    </header>

    <div v-if="renderCount > 0" class="symbol-item-wrap symbol-item-margin" data-content="row">
      <!-- symbol-item -->
      <template v-for="(item, index) in renderItems" :key="index">
        <div v-if="!isReferenceLearn(item.lrnObjTyCd) && isLearnObjectShow(item) && item.objSn === 4888" class="symbol-item">
          <div class="item-row row-content">
            <div class="item-icon">
              <i :class="getSymbolType(item.lrnObjTyCd)"></i>
            </div>

            <div class="item-learning">
              <div class="content-subtitle">{{ item.lrnObjTyNm }}</div>
              <div class="content-title">{{ item.objNm  }}</div>
              <div class="content-texts">
                <span v-if="item.addedMetas.length > 0" class="text">{{ item.addedMetas.join(' ・ ')}}</span>
                <span v-if="item.rangeDate" class="text">{{ item.rangeDate }}</span>
              </div>
              <!-- 학습 점수 처리 -->
              <div class="content-texts">
                <strong class="text-description text-gold">-</strong>
              </div>
            </div>
          </div>
          <div class="item-row row-buttons">
            <button v-if="item.fnshYn === 'Y'" class="kb-btn kb-btn-outline" @click="openLearnWindow(index, 'Y')">
              <span class="text">{{ getButtonText(item) }}</span>
            </button>
            <button v-else-if="canDo(item, currentDate)" class="kb-btn kb-btn-primary" @click="openLearnWindow(index, 'N')">
              <span class="text">{{ getButtonText(item) }}</span>
            </button>
            <button v-else class="kb-btn kb-btn-outline" disabled>
              <span class="text">{{ getButtonText(item) }}</span>
            </button>
          </div>
        </div>
      </template>
    </div>
  </article>
  <article class="content-section" :class="{'pt-10' : evlTestCount > 0, 'pt-0' : evlTestCount === 0}">
    <header class="section-header d-flex">

      <SortButtonSelect2
          v-model="tab"
          v-model:toggle="tabToggle"
          title="코칭 등록 여부"
          :options="[{display: '오늘학습', value: 'today'}, {display: '전체학습', value: 'all'}]"
          sequence-key="value"
          name-key="display"
          button-class="title"
          offset="bottom-left"
      />
      <div v-if="tab === 'today'" class="header-side"><span class="text-sm text-muted">{{ timestampToDateFormat(currentDateStr, 'full-kr') }}</span></div>
    </header>

    <div v-if="renderCount > 0" class="symbol-item-wrap symbol-item-margin" data-content="row">
      <!-- symbol-item -->
      <template v-for="(item, index) in renderItems" :key="index">
        <div v-if="!isReferenceLearn(item.lrnObjTyCd) && isLearnObjectShow(item) && item.objSn !== 4888" class="symbol-item">
          <div class="item-row row-content">
            <div class="item-icon">
              <i :class="getSymbolType(item.lrnObjTyCd)"></i>
            </div>

            <div class="item-learning">
              <div class="content-subtitle">{{ item.lrnObjTyNm }} {{ item.evlProgRate > 0 ? ' - 가능진도율 : '.concat(item.evlProgRate,'%') : ''}}</div>
              <div class="content-title">{{ item.objNm  }}</div>
              <div class="content-texts">
                <span v-if="item.addedMetas.length > 0" class="text">{{ item.addedMetas.join(' ・ ')}}</span>
                <span v-if="item.rangeDate" class="text">{{ item.rangeDate }}</span>
              </div>
              <!-- 학습 점수 처리 -->
              <div class="content-texts">
                <strong class="text-description text-gold" v-html="getScoreUnit(item, params.coldCallUseYn)"></strong>
              </div>
            </div>
          </div>
          <div class="item-row row-buttons">
            <button v-if="item.fnshYn === 'Y'" class="kb-btn kb-btn-outline" @click="openLearnWindow(index, 'Y')">
              <span class="text">{{ getButtonText(item) }}</span>
            </button>
            <button v-else-if="canDo(item, currentDate)" class="kb-btn kb-btn-primary" @click="openLearnWindow(index, 'N')">
              <span class="text">{{ getButtonText(item) }}</span>
            </button>
            <button v-else class="kb-btn kb-btn-outline" disabled>
              <span class="text">{{ getButtonText(item) }}</span>
            </button>
          </div>
        </div>
      </template>
    </div>

    <div v-else class="search-container">
      <div class="result-empty">
        <img src="@/assets/lxp/images/common/img_empty.png" alt="">
        <p class="text">오늘 예정된 학습이 없습니다.</p>
      </div>
    </div>

  </article>
</template>

<script>
import SortButtonSelect2 from '@/components/common/SortButtonSelect2';
import {learnIndexSetup} from '@/assets/js/modules/learn/learn-setup';

export default {
  name: 'MobileLearnIndex',
  components: {SortButtonSelect2},
  props: {
    params: Object,
    items: Array,
    currentDate: Object
  },
  emits: ['openLearn'],
  setup: learnIndexSetup
};
</script>
