<template>
  <article class="content-section">
    <header class="section-header d-flex">
      <h3 class="title">나의 학습 현황</h3>
      <div class="header-side">
        <span v-if="resultParam.lrnMapngRsltSumupSn > 0" class="text-sm text-muted">총점 기준 : {{ resultParam.totScore ? resultParam.totScore : 0 }} / 100점</span>
        <span v-else class="text-sm text-muted text-red">총점 계산중</span>
      </div>
    </header>
    <div class="symbol-item-wrap symbol-learning-wrap">
      <template v-for="(item, index) in items" :key="index">
        <div v-if="checkUseObjects(item)" class="symbol-item">
          <div class="item-icon">
            <i :class="getFinishSymbolType(item)"></i>
          </div>
          <div class="item-learning">
            <div class="content-subtitle">{{ getTargetName(item) }} {{item.fnshCateCdDcd === fnshCateCdDcds.Evaluation ? `- ${item.evlTyNm}` : ''}}</div>
            <div class="content-title">학습 콘텐츠 : {{ getUseObjectCount(item) }}</div>
            <div class="content-texts">
              <span class="text">{{getCurrentValue(item)}} / {{ getTotCount(item) }}{{getTargetUnit(item.fnshCondNm)}}</span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </article>

</template>

<script>
import {learnStatusSetup} from '@/assets/js/modules/learn/learn-setup';

export default {
  name: 'MobileLearnStatus',
  components: {},
  props: {
    items: Array,
    resultParam: Object,
  },
  setup: learnStatusSetup
};
</script>
