<template>
<!--  <article v-if="items.length > 0" class="content-section">-->
  <article class="content-section">
    <header class="section-header header-divider">
      <h3 class="title">{{ title }}</h3>
    </header>
    <div class="mini-notice-container">
      <ul v-if="items.length > 0" class="mini-notice">
        <li v-for="(item, index) in items" :key="index" class="notice-item" :class="{'file': item.fileAtchYn === 'Y'}">
          <router-link v-if="viewRouteName" :to="{name: viewRouteName, params: { mode : refMode, lrnPostSn: item.lrnPostSn }}" class="notice-link"></router-link>
          <a v-else href="javascript:" class="notice-link"></a>

          <h5 class="title">
<!--            <em v-if="item.topFixYn === 'Y'" class="text-gold m-r-1">필독</em>-->
            {{ item.title }}
            <i v-if="isNewIcon(item.regDt)" class="icon-new"></i>
          </h5>

          <div class="meta">
            <span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</span>
            <span class="text">{{ item.inqCnt }} 조회</span>
          </div>
        </li>
      </ul>
      <ul v-else>
        <div class="content-details text-center">등록된 게시물이 없습니다.</div>
      </ul>
      <button v-if="listRouteName" class="kb-btn-mini-notice" @click="goList">
        전체보기
      </button>
    </div>
  </article>
</template>

<script>

import {learnRefSetup} from '@/assets/js/modules/learn/learn-setup';

export default {
  name: 'MobileLearnRef',
  components: {},
  props: {
    title: String,
    listRouteName: String,
    viewRouteName: String,
    refMode: String
  },
  setup: learnRefSetup
};
</script>

