<template>
  <template v-if="windowActive && modalActive && targetIndex > -1">
    <MobileLearnEvaluation
        v-if="modalActive === 'evl'"
        :evl="targetAdded"
        :is-answer="windowOptions.answer"
        @renewInit="renewStartDt"
        @renewEnd="renewEndDt"
    />
    <MobileLearnSurvey
        v-else-if="modalActive === 'svy'"
        :item="targetItem"
        :svy="targetAdded"
        @renewEnd="getLearnObjectResult"
    />
    <MobileLearnCms
        v-else-if="modalActive === 'cms'"
        :cms="targetAdded"
        :item="targetItem"
        :memory-limit="7"
        @renewEnd="getLearnObjectResult"
        :use-cold-call="params.coldCallUseYn === 'Y'"
        :lrn-psblt-hr-div="params.lrnPsbltHrDiv"
    />
  </template>
  <template v-else>
    <LxpMobileHeader title="학습홈" >
      <template v-slot:left>
        <div class="util util-back">
          <router-link :to="{name: 'TrainProceeding'}" class="util-actions util-actions-back"><i class="icon-history-back"></i></router-link>
        </div>
      </template>

      <template v-slot:right>
        <div v-if="modeIdx === 2" class="util util-pencil">
          <router-link :to="{name: 'LearnBoardWrite', params: {lrnPostSn: 0}}" class="util-actions util-actions-pencil">
            <i class="icon-pencil"></i>
          </router-link>
        </div>
      </template>

    </LxpMobileHeader>
    <main v-if="!applyModal" class="kb-main" id="kb-myclass">
      <!-- main-content -->
      <div class="main-content min-component">
        <div class="educourse-view-container">
          <MobileLearnInfo
              v-if="isInit && (modeIdx === 0)"
              :params="params"
              :learns="objectResults"
          />
          <div class="view-contents">
            <MobileLearnRoute
                :is-init="isInit"
                :collective-cnt="collectiveCnt"
                :board-objects="boardObjects"
            />

            <!-- content-section:오늘 예정된 학습 -->
            <template v-if="modeIdx === 0">
              <MobileLearnScheduled
                  :items="objectResults"
                  :current-date="currentDate"
                  @openLearn="openLearn"
              />
              <MobileLearnStatus
                  v-if="statuses.length > 0"
                  :items="statuses"
                  :result-param="resultParam"
              />
              <MobileLearnRef
                  title="공지사항"
                  refMode="notice"
                  list-route-name="LearnBulletin"
                  view-route-name="LearnBulletinView"
              />
              <MobileLearnRef
                  title="자료실"
                  refMode="lib"
                  list-route-name="LearnBulletin"
                  view-route-name="LearnBulletinView"
              />
            </template>

            <MobileLearnIndex
                v-else-if="modeIdx === 1"
                :params="params"
                :items="objectResults"
                :current-date="currentDate"
                @openLearn="openLearn"
            />

            <MobileLearnBoard
                v-else-if="modeIdx === 2"
                :boards="boardObjects"
            />
            <MobileLearnTimetable
                v-else-if="modeIdx === 3"
                :items="objectResults"
            />
          </div>
        </div>


      </div>
      <!-- //main-content -->
    </main>
    <MobileCloudNowPledgeModal v-if="applyModal"
                               v-model="applyModal"
                               :distCrseSn="pledge.distCrseSn"
                               :lrnerId="pledge.lrnerId"
                               :lrnerNm="pledge.lrnerNm"/>
  </template>
</template>

<script>

import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import MobileLearnInfo from '@/components/learn/mobile/MobileLearnInfo';
import MobileLearnRoute from '@/components/learn/mobile/MobileLearnRoute';
import MobileLearnScheduled from '@/components/learn/mobile/MobileLearnScheduled';
import MobileLearnStatus from '@/components/learn/mobile/MobileLearnStatus';
import MobileLearnRef from '@/components/learn/mobile/MobileLearnRef';
import MobileLearnIndex from '@/components/learn/mobile/MobileLearnIndex';
import MobileLearnTimetable from '@/components/learn/mobile/MobileLearnTimetable';
import MobileLearnCms from '@/components/learn/mobile/window/MobileLearnCms';
import MobileLearnEvaluation from '@/components/learn/mobile/window/MobileLearnEvaluation';
import MobileLearnSurvey from '@/components/learn/mobile/window/MobileLearnSurvey';
import {learnMainSetup} from '@/assets/js/modules/learn/learn-setup';
import MobileLearnBoard from '@/components/learn/mobile/MobileLearnBoard';
import MobileCloudNowPledgeModal from "@/components/cloudNow/MobileCloudNowPledgeModal";

export default {
  name: 'MobileLearnMain',
  components: {
    MobileLearnBoard,
    MobileLearnSurvey,
    MobileLearnEvaluation,
    MobileLearnCms,
    MobileLearnTimetable,
    MobileLearnIndex,
    MobileLearnRef,
    MobileLearnStatus, MobileLearnScheduled, MobileLearnRoute, MobileLearnInfo, LxpMobileHeader, MobileCloudNowPledgeModal},
  setup: learnMainSetup
}
</script>
