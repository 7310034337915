<template>
  <article v-if="scheduled.length > 0 && !applyModal" class="content-section pt-0">
    <header class="section-header">
      <h3 class="title">오늘 예정된 학습</h3>
    </header>
    <!-- text-divider-container -->
    <div class="text-divider-container">
      <ul class="text-divider-list">
        <!-- text-divider-item -->
        <li v-for="(item, index) in scheduled" :key="index" class="text-divider-item">
          <a href="javascript:" class="item-link" @click="openLearn(item)"></a>
          <div class="item-title">{{ timestampToDateFormat(item.objBgngDt, 'hh:mm') }} - {{ timestampToDateFormat(item.objEndDt, 'hh:mm') }}</div>
          <div class="item-content">
            <strong>{{ item.objNm }}</strong>
            <div class="badge" v-if="getClassNow(item.objBgngDt, item.objEndDt)">
              <span class="badge-text">NOW</span>
            </div>
          </div>
          <strong class="item-content text-muted">{{ item.lrnObjTyNm }}{{ item.lrnObjTyCd === lrnObjTyCds.Evaluation ? ` - ${item.evlTyNm}` : '' }}</strong>
        </li>
      </ul>
    </div>
    <!-- //text-divider-container -->
  </article>
  <MobileCloudNowPledgeModal v-if="applyModal"
                             v-model="applyModal"
                             :distCrseSn="pledge.distCrseSn"
                             :lrnerId="pledge.lrnerId"
                             :lrnerNm="pledge.lrnerNm"/>
</template>

<script>

import {learnScheduledSetup} from '@/assets/js/modules/learn/learn-setup';
import MobileCloudNowPledgeModal from "@/components/cloudNow/MobileCloudNowPledgeModal";

export default {
  name: 'MobileLearnScheduled',
  components: {MobileCloudNowPledgeModal},
  props: {
    items: Array,
    currentDate: Object
  },
  emits: ['openLearn'],
  setup: learnScheduledSetup
};
</script>
