<template>
  <article class="content-section section-calendar">
    <div class="calendar">
      <div class="calendar-header">
        <h3 class="title">{{ currentWeekNumber.month }}월 {{ currentWeekNumber.weekNo }}주차</h3>
        <button class="kb-btn-left" @click="moveWeek(false)"><span class="text">이전</span></button>
        <button class="kb-btn-right" @click="moveWeek(true)"><span class="text">다음</span></button>
      </div>
      <div class="calendar-container hide-scroll-bar" style="max-width: 100%">
        <div class="calendar-wrap">
          <template v-for="(date, index) in targetWeek" :key="index">
            <div class="day" :class="{'day-disabled': index === 0 || index === 6, 'day-today': (date.year === selectedDate.year && date.month === selectedDate.month && date.day === selectedDate.day )}">
              <a href="javascript:" class="day-link" @click="selectDate(date)"><span class="date">{{ date.day }}</span><span class="text">{{ (date.year === today.year && date.month === today.month && date.day === today.day) ? '오늘' : date.dayofweek }}</span></a>
            </div>
          </template>
        </div>
      </div>
    </div>
  </article>

  <article class="content-section section-margin section-schedule">
    <!-- schedule-list -->
    <ol class="schedule-list">
      <!-- schedule-item -->
      <li v-for="(item, index) in renderItems" :key="index" class="schedule-item">
        <div class="item-column column-time">
          <strong class="text text-gold">{{ (index + 1) }}교시</strong>
        </div>
        <div class="item-column column-content">
          <h4 class="title">{{ item.objNm }}</h4>
          <strong class="text text-muted">{{ item.hour }}:00-{{ item.hour }}:50 {{ item.tutors ? `・ ${item.tutors}` : ''}}</strong>
        </div>
        <div v-if="item.icon" class="item-column column-icons">
          <i :class="item.icon"></i>
        </div>
      </li>
      <!-- schedule-item -->
    </ol>
    <!-- //schedule-list -->
  </article>
</template>

<script>
import {learnTimetableSetup} from '@/assets/js/modules/learn/learn-setup';

export default {
  name: 'MobileLearnTimetable',
  components: {},
  props: {
    items: Array
  },
  setup: learnTimetableSetup
};
</script>
