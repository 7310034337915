<template>
  <div class="kb-form-layer" :class="{'is-active' : toggle}" @click.stop="closeToggle">
    <div class="layer-container">
      <div class="layer">
        <div class="kb-form-layer-options">
          <div class="layer-option">
            <ul class="layer-option-list">
              <li v-for="(item, index) in items" class="layer-option-item" :class="{'is-active' : item.lrnObjDtlDistSn === currentLrnObjDtlDistSn}" :key="index">
                <a href="javascript:" class="layer-option-link" @click.stop="goBoardObject(item.lrnObjDtlDistSn)"><strong class="layer-option-text">{{ item.objNm }}</strong></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {learnBoardTapSetup} from '@/assets/js/modules/learn/learn-board-setup';

export default {
  name: 'MobileLearnBoardTap',
  props: {
    toggle: Boolean,
    items: Array
  },
  emits: ['update:toggle'],
  setup: learnBoardTapSetup
};
</script>
